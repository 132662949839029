@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Archivo:wght@400;600&display=swap);
html {
  height: 100%;
}

*, *:before, *:after {
  box-sizing: border-box;
}

div:focus {
  outline-style: none;
}

canvas:focus {
  outline-style: none;
}

body {
  height: 100%;
  margin: 0;
  font-family: "IBM Plex Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Ubuntu", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 100%;
}

.App {
  height: 100%;
}

.autosizer {
  -webkit-user-select: none;
}

